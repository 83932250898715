(function ($) {
  Drupal.behaviors.sppStickyV1 = {
    attach: function (context, settings) {
      var isDesktop = !!settings && !settings.is_mobile;
      var animationTime = 400;

      if ($('.site-content .spp__container').length && isDesktop) {
        var prodId = $('.js-product').first().data('product-id');
        var prodData = prodcat.data.getProduct(prodId);
        var html = site.template.get({
          name: 'spp_sticky_v1',
          data: prodData
        });
        var $header = $('.site-header', context);

        $header.append(html);

        var $module = $('.js-spp-sticky--v1', context);

        $('.js-sku-menu', $header).selectBox();
        // Init reviews
        $module.trigger('product:init');

        var stickyWaypoint = false;

        function sppSticky() {
          var unisonVars = {
            bps: Unison.fetch.all(),
            bp: Unison.fetch.now()
          };
          var isDesktop = parseInt(unisonVars.bp.width, 10) >= parseInt(unisonVars.bps.large, 10);

          if (stickyWaypoint) {
            stickyWaypoint.destroy();
          }
          if (isDesktop) {
            // Reveal SPP Sticky
            stickyWaypoint = new Waypoint({
              element: $('.product-full__detail.product-full__description', context),
              handler: function (direction) {
                if (direction === 'down') {
                  $module
                    .show()
                    .find('.spp-sticky__container')
                    .stop(true, true)
                    .fadeIn(animationTime);
                } else {
                  $module
                    .show()
                    .find('.spp-sticky__container')
                    .stop(true, true)
                    .fadeOut(animationTime);
                }
              }
            });
          } else {
            $module.stop(true, true).fadeOut(animationTime);
          }
          $('.spp-sticky__container').css('display', 'none');
        }
        sppSticky();
      }
    }
  };
})(jQuery);
